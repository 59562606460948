import ReactDOM from "react-dom";
import * as React from "react";

import { ContactForm } from "../components/forms/Contact";
import { ApplicationForm } from "../components/forms/Application";

export async function getWebform(formId: string): Promise<void> {
  if (formId == "contact") {
    ReactDOM.render(<ContactForm />, document.getElementById(`form_${formId}`));
    return;
  }

  if (formId == "apply") {
    ReactDOM.render(
      <ApplicationForm />,
      document.getElementById(`form_${formId}`)
    );
    return;
  }
}
