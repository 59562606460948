import { states, getStateOptions } from "./states";
import { getWebform } from "./getWebform";

export { states, getStateOptions, getWebform };

export interface ContentWithForms {
  html: string;
  forms: string[];
}

export function processFormShortCodes(html: string): ContentWithForms {
  const forms = [];
  const foundIndex = html.indexOf("[form: ");
  if (foundIndex != -1) {
    const startIndex = foundIndex + 6;
    const endIndex = html.indexOf("]");

    if (endIndex > -1) {
      const formId = html.slice(startIndex, endIndex).trim();
      const formToken = `[form: ${formId}]`;

      html = html.split(formToken).join("<div id='form_" + formId + "'></div>");
      forms.push(formId);
    }
  }

  return {
    html,
    forms,
  };
}

export function processInlineImages(html: string): string {
  html = html.replace(
    new RegExp(`src="/sites/default`, "g"),
    `src="${process.env.GATSBY_CMS_BASE_URL}/sites/default`
  );

  return html;
}
