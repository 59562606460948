import * as React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import * as Sentry from "@sentry/gatsby";

import * as Yup from "yup";
import PasswordStrengthBar from "react-password-strength-bar";

import { getStateOptions, states } from "../../lib";
import axios from "axios";

interface ApplicationFormProps {
  firstname: string;
  lastname: string;
  title: string;
  address: string;
  address_2?: string;
  city: string;
  state: string;
  zipcode: string;
  email: string;
  password: string;
  confirm_password: string;
  student_enrollment: number;
  school_district: string;
  school_building: string;
}

const initialValues: ApplicationFormProps = {
  firstname: "",
  lastname: "",
  title: "",
  address: "",
  address_2: "",
  city: "",
  state: "",
  zipcode: "",
  email: "",
  password: "",
  confirm_password: "",
  student_enrollment: 0,
  school_building: "",
  school_district: "",
};

const testValues: ApplicationFormProps = {
  firstname: "Jane",
  lastname: "Doe",
  title: "Ms.",
  address: "123 Main St",
  address_2: "Unit A",
  city: "Harrisburg",
  state: "SD",
  zipcode: "57032",
  email: "jdoe@example.net",
  password: "enter123$$$",
  confirm_password: "enter123$$$",
  student_enrollment: 12,
  school_district: "Harrisburg",
  school_building: "Liberty",
};

const validationSchema = Yup.object().shape({
  firstname: Yup.string().min(2).required("Your first name is required"),
  lastname: Yup.string().min(2).required("Your last name is required"),
  title: Yup.string().min(2).required("Your title is required"),
  address: Yup.string().min(8).required("Your address is required"),
  city: Yup.string().min(3).required("Your city is required"),
  state: Yup.string().min(2).required("Your state is required"),
  zipcode: Yup.string().min(5).required("Your zipcode is required"),
  student_enrollment: Yup.number()
    .moreThan(0)
    .required("You must be enrolling at least one student"),
  school_district: Yup.string()
    .min(1)
    .required("You must enter your school district"),
  school_building: Yup.string()
    .min(1)
    .required("You must enter your school building"),
  password: Yup.string().min(8).required("You must enter a password"),
  confirm_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
  email: Yup.string()
    .email("Must be a valid email")
    .required("Your email address is required"),
});

const titleOptions: { label: string; value: string }[] = [
  {
    label: "Mrs.",
    value: "Mrs.",
  },
  {
    label: "Ms.",
    value: "Ms.",
  },
  {
    label: "Mr.",
    value: "Mr.",
  },
];

export function ApplicationForm() {
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const [formError, setFormError] = React.useState<string | undefined>(
    undefined
  );

  const API_URL = process.env.GATSBY_API_URL;
  const POSTURL = `${API_URL}/api/v1/user/register`;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        setFormError(undefined);
        try {
          await axios.post(POSTURL, values);
          setSubmitted(true);
          resetForm();
        } catch (err) {
          if (err.response) {
            Sentry.captureException(err.response);
            setFormError(err.response.data);
          } else {
            Sentry.captureException(err);
            setFormError(err.message);
          }
        }
      }}
    >
      {({ isSubmitting, dirty, values, handleChange }) => (
        <Form className="form">
          {formError && (
            <div className="panel panel-danger">
              <strong>Submission Error:</strong>
              <br />
              {formError}
            </div>
          )}

          {submitted && !dirty && (
            <div className="panel">
              <strong>Thank you for your application!</strong>
              <br />
              You will receive a follow up email confirming receipt of your
              application, with instructions on when and how you can login.
            </div>
          )}
          {isSubmitting && <div className="loading"></div>}

          {!submitted && (
            <>
              <fieldset>
                <legend>Personal Information</legend>
                <div
                  className="field-cols"
                  style={{
                    gridTemplateColumns: "32% 32% 30%",
                  }}
                >
                  <div className="field">
                    <label>
                      First Name <span className="required">*</span>
                    </label>
                    <Field type="text" name="firstname" />
                    <span className="error-detail">
                      <ErrorMessage name="firstname" />
                    </span>
                  </div>
                  <div className="field">
                    <label>
                      Last Name <span className="required">*</span>
                    </label>
                    <Field type="text" name="lastname" />
                    <span className="error-detail">
                      <ErrorMessage name="lastname" />
                    </span>
                  </div>
                  <div className="field">
                    <label>
                      Title <span className="required">*</span>
                    </label>
                    <Select
                      placeholder="Select a Title"
                      isSearchable={false}
                      styles={{
                        container: (provided, state) => ({
                          ...provided,
                          height: 42,
                          marginTop: 10,
                          marginBottom: 10,
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          height: 42,
                          borderColor: state.isFocused ? "#3d4954" : "#eee",
                          borderWidth: 2,
                          borderRadius: 0,
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          height: 43,
                        }),
                        input: (provided) => ({
                          ...provided,
                          height: 26,
                          position: "relative",
                          top: -10,
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          fontSize: "1rem",
                        }),
                      }}
                      options={titleOptions}
                      onChange={(selected) => {
                        const event = {
                          target: {
                            name: "title",
                            value: selected?.value,
                          },
                        };
                        handleChange(event);
                      }}
                    />
                    <span className="error-detail">
                      <ErrorMessage name="title" />
                    </span>
                  </div>
                </div>
                <div className="field">
                  <label>
                    Address <span className="required">*</span>
                  </label>
                  <Field type="text" name="address" />
                  <span className="error-detail">
                    <ErrorMessage name="address" />
                  </span>
                </div>
                <div className="field">
                  <label>Address 2</label>
                  <Field type="text" name="address_2" />
                  <span className="error-detail">
                    <ErrorMessage name="address_2" />
                  </span>
                </div>
                <div
                  className="field-cols"
                  style={{
                    gridTemplateColumns: "33% 23% 35%",
                  }}
                >
                  <div className="field">
                    <label>
                      City <span className="required">*</span>
                    </label>
                    <Field type="text" name="city" />
                    <span className="error-detail">
                      <ErrorMessage name="city" />
                    </span>
                  </div>
                  <div className="field">
                    <label>
                      State <span className="required">*</span>
                    </label>
                    <Select
                      placeholder="Select a State"
                      options={getStateOptions()}
                      onChange={(selected) => {
                        const event = {
                          target: {
                            name: "state",
                            value: selected?.value,
                          },
                        };
                        handleChange(event);
                      }}
                      styles={{
                        container: (provided, state) => ({
                          ...provided,
                          height: 42,
                          marginTop: 10,
                          marginBottom: 10,
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          height: 42,
                          borderColor: state.isFocused ? "#3d4954" : "#eee",
                          borderWidth: 2,
                          borderRadius: 0,
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          height: 43,
                        }),
                        input: (provided) => ({
                          ...provided,
                          height: 26,
                          position: "relative",
                          top: -10,
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          fontSize: "1rem",
                        }),
                      }}
                    />

                    <span className="error-detail">
                      <ErrorMessage name="state" />
                    </span>
                  </div>
                  <div className="field">
                    <label>
                      Zipcode <span className="required">*</span>
                    </label>
                    <Field type="text" name="zipcode" />
                    <span className="error-detail">
                      <ErrorMessage name="zipcode" />
                    </span>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>Class Information</legend>
                <div className="field">
                  <label>
                    Student Enrollment Count <span className="required">*</span>
                  </label>
                  <Field type="number" name="student_enrollment" />
                  <span className="error-detail">
                    <ErrorMessage name="student_enrollment" />
                  </span>
                </div>
                <div className="field">
                  <label>
                    School District <span className="required">*</span>
                  </label>
                  <Field type="text" name="school_district" />
                  <span className="error-detail">
                    <ErrorMessage name="school_district" />
                  </span>
                </div>
                <div className="field">
                  <label>
                    School Building <span className="required">*</span>
                  </label>
                  <Field type="text" name="school_building" />
                  <span className="error-detail">
                    <ErrorMessage name="school_building" />
                  </span>
                </div>
              </fieldset>
              <fieldset>
                <legend>Account Information</legend>
                <div className="field">
                  <label>
                    Email <span className="required">*</span>
                  </label>
                  <Field type="email" name="email" />
                  <span className="error-detail">
                    <ErrorMessage name="email" />
                  </span>
                </div>
                <div
                  className="field-cols"
                  style={{
                    gridTemplateColumns: "47% 47%",
                  }}
                >
                  <div className="field">
                    <label>
                      Password <span className="required">*</span>
                    </label>
                    <Field type="password" name="password" />
                    <PasswordStrengthBar password={values.password} />
                    <span className="error-detail">
                      <ErrorMessage name="password" />
                    </span>
                  </div>
                  <div className="field">
                    <label>
                      Confirm Password <span className="required">*</span>
                    </label>
                    <Field type="password" name="confirm_password" />
                    <span className="error-detail">
                      <ErrorMessage name="confirm_password" />
                    </span>
                  </div>
                </div>
                <div>
                  <button type="submit" disabled={isSubmitting}>
                    Send Application
                  </button>
                </div>
              </fieldset>
            </>
          )}
          {formError && (
            <div className="panel panel-danger">
              <strong>Submission Error:</strong>
              <br />
              {formError}
            </div>
          )}

          {submitted && !dirty && (
            <div className="panel">
              <strong>Thank you for your application!</strong>
              <br />
              You will receive a follow up email confirming receipt of your
              application, with instructions on when and how you can login.
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}
