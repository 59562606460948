import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import MainLayout from "../components/MainLayout";

//@ts-ignore
import * as styles from "./page.module.css";
import { getWebform, processFormShortCodes, processInlineImages } from "../lib";

const DrupalPage = (props: any) => {
  const contentHtml = processInlineImages(props.data.nodePage.body.processed);
  const content = processFormShortCodes(contentHtml);

  React.useEffect(() => {
    content.forms.forEach((formId) => {
      getWebform(formId);
    });
  }, [content.forms]);

  return (
    <MainLayout>
      <Helmet>
        <title>{props.data.nodePage.title} | Tour SD!</title>
      </Helmet>
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.inner}>
            <h1>{props.data.nodePage.title}</h1>
          </div>
          <span className={styles.gradient}></span>
        </header>

        <div className={styles.content}>
          <div className={styles.inner}>
            <div
              dangerouslySetInnerHTML={{
                __html: content.html,
              }}
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default DrupalPage;

export const query = graphql`
  query ($id: String) {
    nodePage(id: { eq: $id }) {
      id
      title
      body {
        processed
      }
    }
  }
`;
