import * as React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import * as Sentry from "@sentry/gatsby";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Your name is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .required("Your email address is required"),
  school_organization: Yup.string().required(
    "Your school or organization is required"
  ),
  message: Yup.string().required("Your question or message is required"),
});

export function ContactForm() {
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const [formError, setFormError] = React.useState<string | undefined>(
    undefined
  );

  const API_URL = process.env.GATSBY_API_URL;
  const POST_URL = `${API_URL}/api/v1/user/contact`;
  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        school_organization: "",
        phone: "",
        message: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        setFormError(undefined);

        try {
          await axios.post(POST_URL, values);
          setSubmitted(true);
          resetForm();
        } catch (err) {
          if (err.response) {
            Sentry.captureException(err.response);
            setFormError(err.response.data);
          } else {
            Sentry.captureException(err);
            setFormError(err.message);
          }
        }
      }}
    >
      {({ isSubmitting, dirty }) => (
        <Form className="form">
          {formError && (
            <div className="panel panel-danger">
              <strong>Submission Error:</strong>
              <br />
              {formError}
            </div>
          )}

          {submitted && !dirty && (
            <div className="panel">
              <strong>Thank you for your message!</strong>
              <br />A member of the team will respond to you shortly.
            </div>
          )}

          {isSubmitting && <div className="loading"></div>}
          <fieldset>
            <legend>Contact Us</legend>
            <div className="field">
              <label>
                Name <span className="required">*</span>
              </label>
              <Field type="text" name="name" disabled={isSubmitting} />
              <span className="error-detail">
                <ErrorMessage name="name" />
              </span>
            </div>
            <div className="field">
              <label>
                Email <span className="required">*</span>
              </label>
              <Field type="email" name="email" />
              <span className="error-detail">
                <ErrorMessage name="email" />
              </span>
            </div>
            <div className="field">
              <label>
                School / Organization <span className="required">*</span>
              </label>
              <Field type="text" name="school_organization" />
              <span className="error-detail">
                <ErrorMessage name="school_organization" />
              </span>
            </div>
            <div className="field">
              <label>Phone</label>
              <Field type="text" name="phone" />
              <span className="error-detail">
                <ErrorMessage name="phone" />
              </span>
            </div>
            <div className="field">
              <label>
                Message <span className="required">*</span>
              </label>
              <Field as="textarea" name="message" />
              <span className="error-detail">
                <ErrorMessage name="message" />
              </span>
            </div>
            <div>
              <button type="submit" disabled={isSubmitting}>
                Send Message
              </button>
            </div>
          </fieldset>
          {formError && (
            <div className="panel panel-danger">
              <strong>Submission Error:</strong>
              <br />
              {formError}
            </div>
          )}

          {submitted && !dirty && (
            <div className="panel">
              <strong>Thank you for your message!</strong>
              <br />A member of the team will respond to you shortly.
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}
